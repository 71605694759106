import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    DETAILED: "Details",
    MOCKUPS: "Mockups",
    YOUTUBE: "Youtube Videos",
    VIMEO: "Vimeo Videos",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "White Star Trading",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Deploy Network Infrastructure and wireless network",
        client: "MAF Presidential office",
        technologies: "IT Infrastructure",
        industry: "Technology",
        date: "May 2023",
        /*url: {
          name: "www.example.com",
          link: "https://www.example.com",
        },*/
        sliderImages: [
          "images/projects/whitestar1.jpeg",
          "images/projects/whitestar2.jpeg",
        ],
      },
      thumbImage: "images/projects/whitestar1.jpeg",
      categories: [filters.DETAILED],
    },
    {
      title: "AECOM Consultants",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Handles the upgrade of core network infrastructure in collaboration with UK IT team for different offices in Dubai, Abu Dhabi and Al ain offices.",
        client: "Pivital",
        technologies: "IT Infrastructure",
        industry: "Technology",
        date: "August 2020",
        /*url: {
          name: "www.example.com",
          link: "https://www.example.com",
        },*/
        sliderImages: [
          "images/projects/aecom.jpg",
          "images/projects/aecom2.jpg",
          "images/projects/aecom3.jpg",
          "images/projects/aecom4.jpg",
          "images/projects/aecom5.jpg",
          "images/projects/aecom6.jpg",
          "images/projects/aecom7.jpg",
          "images/projects/aecom8.jpg",
          "images/projects/aecom9.jpg",
          "images/projects/aecom10.jpg",
          "images/projects/aecom11.jpg",
          "images/projects/aecom12.jpg",
          "images/projects/aecom13.jpg",
          "images/projects/aecom14.jpg",
          "images/projects/aecom15.jpg",
          "images/projects/aecom16.jpg",
        ],
      },
      thumbImage: "images/projects/aecom17.jpg",
      categories: [filters.DETAILED],
    },
    {
      title: "Tiffany and Co",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Handles deployment of IT Infrastructure, POS system and security system in Stores in Middle East (Riyadh, Jedah and Kor pakan, Kuwait).",
        client: "TCO IT Department",
        technologies: "IT Infrastructure",
        industry: "Technology",
        date: "November 2020 and 2021",
        /*url: {
          name: "www.example.com",
          link: "https://www.example.com",
        },*/
        sliderImages: [
          "images/projects/tco2.jpg",
          "images/projects/tco3.jpg",
          "images/projects/tco4.jpg",
          "images/projects/tco6.jpg",
          "images/projects/tco8.jpg",
          "images/projects/tco9.jpg",
        ],
      },
      thumbImage: "images/projects/tco1.jpg",
      categories: [filters.DETAILED],
    },
    {
      title: "Kimberly Clark Middle East",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Conducted Security Audit for Kimberly Clark in Bahrain and KSA plant.",
        client: "Convergint UK",
        technologies: "IT Infrastructure",
        industry: "Technology",
        date: "April 2021",
        /*url: {
          name: "www.example.com",
          link: "https://www.example.com",
        },*/
        sliderImages: [
          "images/projects/kc3.jpg",
          "images/projects/kc4.jpg",
          "images/projects/kc5.jpg",
          "images/projects/kc6.jpg",
          "images/projects/kc7.jpg",
          "images/projects/kc8.jpg",
          "images/projects/kc9.jpg",
          "images/projects/kc10.jpg",
          "images/projects/kc11.jpg",
          "images/projects/kc13.jpg",
          "images/projects/kc14.jpg",
          "images/projects/kc15.jpg",
          "images/projects/kc16.jpg",
          "images/projects/kc17.jpg",
          "images/projects/kc18.jpg",
          "images/projects/kc19.jpg",
          "images/projects/kc20.jpg",
          "images/projects/kc21.jpg",
          "images/projects/kc22.jpg",
        ],
      },
      thumbImage: "images/projects/kc2.jpg",
      categories: [filters.DETAILED],
    },
    {
      title: "MAJID AL FUTAIM WIFI VILLA PROJECT",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Design Implement and Maintain the home of one of the most successful mall owners in the Emirates. The whole house and plot of land is fully covered with wireless network. Using the latest WIFI technology.",
        client: "MAF IT",
        technologies: "WIFI 6",
        industry: "Technology",
        date: "July 2019",
        /*url: {
          name: "www.example.com",
          link: "https://www.example.com",
        },*/

        sliderImages: [
          "images/projects/maf2.jpeg",
          "images/projects/maf3.jpeg",
          "images/projects/maf4.jpeg",
          "images/projects/maf5.jpeg",
        ],
      },

      thumbImage: "images/projects/maf1.jpeg",

      categories: [filters.DETAILED],
    },
    {
      title: "ONE LIFE CAFÉ AND RESTAURANT at D3",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Design and implement CCTV and Wireless Network for Onelife restaurant as wireless network is a crucial part of any restaurant and with 11 CCTV camera to ensure the safety and deterred anyone who have ill intention.",
        client: "Necif Restaurant Owner",
        technologies: "WiFI and CCTV",
        industry: "Technology",
        date: "January 2019",
        /*url: {
          name: "www.example.com",
          link: "https://www.example.com",
        },*/
        sliderImages: [
          "images/projects/onelife.jpg",
          "images/projects/onelife2.jpg",
          "images/projects/onelife3.jpeg",
          "images/projects/onelife4.jpeg",
        ],
      },
      thumbImage: "images/projects/onelife5.jpg",
      categories: [filters.DETAILED],
    },
   
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section bg-light"}>
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Some of my most recent projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
