import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "WiFi setup & Improvement for Home, Office & Outdoor",
      desc: "We can help you with the planning, designing, installing, configuring, and testing of a wireless local area network (WLAN) for your organization. This includes tasks such as selecting and installing Wi-Fi routers and access points, setting up and securing wireless networks, configuring Wi-Fi security protocols, and testing the network for coverage and performance.",
      icon: "fas fa-wifi",
    },
    {
      name: "IT Support",
      desc: " IT support also includes maintenance, installation, and training for technology products and systems. The goal of IT support is to ensure that users have seamless and efficient access to technology resources and are able to effectively utilize them in their daily work.",
      icon: "fas fa-desktop",
    },
    {
      name: "Supply of IT Equipment",
      desc: "acquiring, procuring, and delivering technology hardware and software products to your organization. This includes items such as computers, servers, printers, smartphones, software licenses, and other related equipment. Our goal in IT equipment supply is to provide your organization with the necessary technology resources to support its operations and achieve its objectives",
      icon: "fas fa-box",
    },
    {
      name: "Structured Cabling ",
      desc: "We do the design, installation, and maintenance of a building’s communication infrastructure. This includes the cabling, connectors, and other components used for voice, data, and video transmission. The goal of structured cabling is to provide a reliable and efficient means of transmitting signals between devices, both within a single building and between different buildings. Structured cabling involves a range of activities, including planning and design, installation, testing, and certification",
      icon: "fas fa-ethernet",
    },
    {
      name: "Email Administration",
      desc: "management and maintenance of an organization’s email system. This includes tasks such as setting up and configuring email accounts, managing email servers and storage, ensuring email security and data protection, implementing email policies and procedures and providing support for email users. Email administrators are responsible for ensuring that the email system is available, reliable, and secure and that it meets the needs of the organization. ",
      icon: "fas fa-envelope",
    },
    {
      name: "CCTV and Access Control",
      desc: "Our CCTV and access control systems provide comprehensive security coverage for facilities, enabling your organizations to protect your assets, monitor activity, and ensure the safety of your personnel. These systems are typically integrated with other security technologies and can be managed remotely, providing your organizations with real-time visibility and control over their security operations.",
      icon: "fas fa-video",
    },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How I can help your next project
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
